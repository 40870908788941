@import '../../../sass/variables.scss';
@import '../../../sass/mixin.scss';
@import '../../../sass/animations.scss';

.info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  column-gap: 4%;
  row-gap: 1%;
  font-size: 0.625rem;
  margin-top: 5vw;
  @media only screen and (max-width: 1110px) {
    grid-template-columns: 1fr;
    margin-bottom: 2rem;
  }
  @media only screen and (max-width: 768px) {
    font-size: 0.5rem;
  }
  &__img {
    animation: moveFromLeft 0.8s ease-out forwards;
    img {
      display: block;
      width: 100%;
    }
    @media only screen and (max-width: 1110px) {
      order: 2;
      width: 90%;
    }
  }
  .content {
    width: 90%;
    &__header {
      font-size: 4em;
      font-weight: 600;
      color: #2f2f2f;
    }
    &__para {
      font-style: normal;
      font-weight: 400;
      font-size: 1.6em;
      color: #2f2f2f;
      opacity: 0.7;
      margin-top: 1%;
      margin-bottom: 6%;
    }
    .features {
      list-style: none;
      padding: 0;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      column-gap: 5vw;
      .feature {
        display: grid;
        grid-template-columns: 6em 1fr;
        grid-template-rows: min-content min-content;
        align-items: center;
        column-gap: 3%;
        margin-bottom: 3%;
        .icon {
          grid-row: 1/3;
          width: 100%;
          display: block;
          img {
            display: block;
            width: 100%;
          }
        }
        .title {
          font-style: normal;
          font-weight: 600;
          font-size: 2.2em;
          color: #282a2e;
        }
        .para {
          font-weight: 400;
          font-size: 1.6em;
          letter-spacing: 0.5px;
          color: #2f2f2f;
          opacity: 0.7;
        }
      }
    }
  }
}
