@import '../../../sass/animations.scss';
.history-bk {
  background: rgba(229, 238, 244, 0.3);
  border-radius: 32px;
  width: 100%;
  margin-top: 5vh;
}
.history {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  gap: 5vw;
  padding: 4vw;
  font-size: 0.65rem;

  @media only screen and (max-width: 1140px) {
    grid-template-columns: 1fr;
  }
  @media only screen and (max-width: 768px) {
    font-size: 0.5rem;
  }
  .content {
    position: relative;
    &::before {
      content: '';
      width: 52px;
      height: 52px;
      border-radius: 50%;
      background-color: #fff2b7;
      position: absolute;
      top: 0%;
      animation: circleRotate 5s infinite linear forwards reverse;
      &:lang(en) {
        left: -4%;
      }
      &:lang(ar) {
        right: -4%;
      }
    }
    .header {
      font-weight: 600;
      font-size: 4em;
      color: #2f2f2f;
      position: relative;
      z-index: 2;
      margin-bottom: 1rem;
    }
    .para {
      font-weight: 500;
      font-size: 2em;
      color: #2f2f2f;
    }
  }
  .video {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 80%;
    height: 100%;
    .thumb {
      position: relative;
      img {
        display: block;
        position: relative;
        width: 100%;
        z-index: 3;
      }
      .play-icon {
        position: absolute;
        top: 31%;
        left: 24%;
        width: 160px;
        cursor: pointer;
        z-index: 3;
      }
      &::before {
        content: '';
        width: 60px;
        height: 62px;
        border-radius: 20px;
        background-color: #80e3ea;
        position: absolute;
        top: -3%;
        left: -3%;
        z-index: 1;
        animation: squareRotate 5s infinite linear forwards reverse;
      }
      &::after {
        content: '';
        width: 100px;
        height: 105px;
        border-radius: 20px;
        background-color: #ffb954;
        position: absolute;
        bottom: -3%;
        right: -3%;
        z-index: 1;
        animation: squareRotate 5s infinite linear forwards reverse;
      }
    }
    .vidSrc {
      display: none;
    }
  }
}
