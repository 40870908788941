.login {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  background-color: #35cced;
  .leftPart {
    width: 65%;
    background-color: white;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    @media (max-width: 768px) {
      width: 90%;
     }
  }
  .rightPart {
    width: 35%;
    background-color: #35cced;
    @media (max-width: 768px) {
     width: 10%;
     img {
      display: none;
     }
    }
  }
  .Buttons {
    display: flex;
    flex-direction: row;
    margin-left: 1rem;
    margin-top: 5px;
    width: 100%;
    justify-content: center;
  }
  .SocialBtn {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 15px;
    padding-bottom: 15px;

    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;

    text-align: center;

    border: 1px solid #35cced;
    border-radius: 10px;

    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .orClass {
    width: 100%;
    font-family: "Righteous";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;

    color: #c1c1c1;
  }
  .icon {
    margin-right: 20px;
    color: #35cced;
  }
}
