.footer {
  // background-color: #e5eef4;
  padding: 4vw 10px 10vw 10px;
  margin-top: 4vw;
  font-size: 0.65rem;
  position: relative;
  .policies-item {
    font-size: 14px;
    &:hover {
      text-decoration: underline;
    }
  }
  .footer-title {
    font-style: normal;
    font-weight: 700;
    font-size: 2em;
    color: #00c8d5;
  }
  a {
    font-size: 1.4em;
    color: #737373;
  }
  .logo-para {
    font-size: 1.4em;
    color: #737373;
  }
  .footer-links {
    display: flex;
    gap: 20px;
    cursor: pointer;
    .icon {
      color: #00c8d5;
    }
  }
  .footer-payments {
    display: flex;
    justify-content: space-between;
    img {
      height: 50px;
    }
  }
  .copyrights {
    text-align: center;
    margin-top: 3rem;
    z-index: 99;
  }
  .footer-shape {
    position: absolute;
    bottom: 0;
    left: 0;
    @media screen and (max-width: 992px) {
      background-position: bottom !important;
    }
  }
  .about-link {
    &:hover {
      text-decoration: underline;
    }
  }
  
}
