.loginForm {
  display: flex;
  flex-direction: column;
  gap: 1.6em;
  width: 80%;
  @media screen and (max-width: 768px) {
    font-size: 0.5rem;
  }
  .inputBtn {
    outline: 0;
    border-width: 0 0 1px;
    border-color: #c1c1c1;
    padding-bottom: 12px;

    font-size: 2em;

    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    cursor: text;
    &:focus {
      outline: none;
      box-shadow: none;
      border-color: #a3aaa3;
    }
    &::placeholder {
      color: #5e6d55;
    }
  }
  .formBtn {
    background: #00c8d5;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 2em;
    color: #fff;
    padding: 12px;
    border: none;
    outline: none;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.06))
      drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1));
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: darken(#00c8d5, 2%);
    }
  }
}
