.video-wrapper {
  padding: 1rem;
}

.videos-container {
  border-radius: 20px;
  width: 200px;
  background-color: rgb(255, 255, 255);
  height: 100px;

  display: flex;
  position: relative;

  margin-bottom: 20px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 10px 0 rgba(0, 0, 0, 0.1);
}
.videos-container:hover {
  border: 3px solid rgb(44, 207, 207);
}

.image-container {
  position: absolute;
  background-color: red !important;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  border-radius: 20px 0px 0px 20px;
}

.play-container {
  position: absolute;
  border: 0;
  border-radius: 100%;
  background-color: rgb(44, 207, 207);
  height: 45%;
  width: 20%;
  z-index: 9;
  top: 26%;
  left: 13%;
}

.text1 {
  position: absolute;
  left: 45%;
  font-weight: bold;
}
.text2 {
  position: absolute;
  left: 45%;
  margin-top: 40px;
  font-size: small;
}
