.navbar-section {
  background-color: #e6fafb;
  //   .navBarTab {
  //     display: flex;
  //     flex-direction: row;
  //     justify-content: flex-start;
  //     align-content: center;
  //     align-items: center;

  //     padding-top: 5px;
  //     padding-bottom: 5px;
  //     margin-bottom: 20px;
  //     border-radius: 50px;
  //     font-family: "Roboto";
  //     font-style: normal;
  //     font-weight: 900;
  //     font-size: 16px;
  //     line-height: 28px;
  //     text-align: center;
  //     color: #000;

  //     &:hover {
  //       background-color: #00c8d5;
  //       box-shadow: 0px 0px 15px rgba(0, 90, 96, 0.3);
  //       color: #ffffff;
  //     }
  //     &:active {
  //       color: #ffffff;
  //       background-color: #00c8d5;
  //       box-shadow: 0px 0px 15px rgba(0, 90, 96, 0.3);
  //     }
  //   }

   

}
.offcanvas-body{
  .stngBtn {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    align-items: center;

    border-radius: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 20px;

    font-family: "Roboto";
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #ffffff;
    background-color: #00c8d5;
    box-shadow: 0px 0px 15px rgba(0, 90, 96, 0.3);
  }
  .tabText {
  }
  .icon {
    margin-left: 15px;
    margin-right: 20px;
    width: 17px;
    color: white;
  }
  .offerBanner {
    background-color: #bc73ec;
    width: 80%;
    height: 55%;
    align-self: center;
    margin-bottom: 15px;
    border-radius: 24px;
    padding: 15px;
    position: inherit;
  }
  .mainOfferText {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
    color: #ffffff;
  }
  .subOfferText {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    color: #ffffff;
    margin-top: 5px;
    max-width: 60%;
  }
  .offerButton {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7px 16px;
    gap: 10px;

    background: linear-gradient(102.25deg, #04afba 11.68%, #00c8d5 89.82%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;

    /* identical to box height, or 20px */
    text-align: center;

    color: #ffffff;
  }
  .nav-item {
    transition: all 0.1s ease-in-out;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    align-items: center;

    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 20px;
    border-radius: 50px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #000;

    &:hover,
    &:active {
      background-color: #00c8d5;
      box-shadow: 0px 0px 15px rgba(0, 90, 96, 0.3);
      color: #ffffff;
    }
  }
  .nav-item {
    @media (max-width: 570px) {
      font-size: 14px;
    }
  }
  .navbar-sign-up {
    transition: all 0.2s ease-in-out;
    &:hover {
      color: #fff;
      background-color: #00c8d5;
    }
  }
  .navbar-log-in {
    transition: all 0.2s ease-in-out;
    border: 2px solid #fff;
    &:hover {
      color: #fff;
      background-color: #00c8d5;
      border-color: #00c8d5;
    }
  }
  .view-profile {
    color: #00c8d5;
    border: 1px solid #00c8d5;
    border-radius: 15px;
    transition: all 0.1s ease-in-out;
    &:hover {
      color: #fff;
      border: 1px solid #fff;
      background-color: #00c8d5;
    }
  }

}