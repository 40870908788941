@import 'variables.scss';
@mixin blue-btn($width, $bg) {
  background-color: $bg;
  color: #fff;
  border-radius: 50px;
  padding: 12px;
  box-shadow: 0px 0px 15px rgba(0, 90, 96, 0.3);
  width: $width !important;
  cursor: pointer;
  border: none;
}

@mixin text-style {
  font-size: $fontSize;
  color: $blackColor;
  font-weight: $fontWeight;
}
