@import "../../../sass/variables.scss";
@import "../../../sass/mixin.scss";
@import "../../../sass/animations.scss";

.banner {
  font-size: 9px;
  padding: 50px;
  width: 80% !important;

  &__header--l1 {
    font-size: 5.4em;
    color: $purpleColor;
    margin-bottom: 0.4em;
    animation: moveFromLeft 0.8s ease-out forwards;
    opacity: 0;
    transform: translateX(-100%);
    &:lang(ar) {
      transform: translateX(100%);
      animation: moveFromRight 0.8s ease-out forwards;
    }
  }
  &__header--l2 {
    font-size: 5.4em;
    color: $orangeColor;
    margin-bottom: 5%;
    line-height: 1.1em;
    animation: moveFromLeft 0.8s ease-out 0.2s forwards;
    opacity: 0;
    transform: translateX(-100%);
    &:lang(ar) {
      transform: translateX(100%);
      animation: moveFromRight 0.8s ease-out 0.2s forwards;
    }
  }
  &__para {
    font-size: 2em;
    color: $greyColor2;
    margin-bottom: 4%;
    animation: moveFromLeft 0.8s ease-out 0.4s forwards;
    opacity: 0;
    transform: translateX(-100%);
    &:lang(ar) {
      transform: translateX(100%);
      animation: moveFromRight 0.8s ease-out 0.4s forwards;
    }
  }
  .img-absolute {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    z-index: -99;
  }
  .action {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: max(10px, 2%);
    overflow: hidden;
    animation: moveFromBottom 0.8s ease-out forwards;
    .btn-solid {
      width: max(21%, 7em);
      padding: 0.2em;
      border-radius: 32px;
      font-size: 2em;
      font-weight: 600;
      background-color: $blueColor;
      border: 2px solid $blueColor;
      color: #fff;
    }
    .btn-outline {
      width: max(21%, 7em);
      padding: 0.2em 1em;
      border-radius: 32px;
      font-size: 2em;
      color: $blueColor;
      font-weight: 600;
      box-shadow: none;
      border: 2px solid $blueColor;
      @media (max-width: 720px) {
        width: 120px;
      }
    }
  }
  @media only screen and (min-width: 1400px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 980px) {
    font-size: 7px;
  }
  @media only screen and (max-width: 433px) {
    font-size: 5px;
    padding: 4%;
    margin-left: 20px !important;
  }
}
