body {
  margin: 0;
}
body:lang(en),
body:lang(er) {
  font-family: 'Inter', sans-serif;
}
body:lang(ar) {
  font-family: 'Cairo', sans-serif;
}
html:lang(ar) {
  direction: rtl;
}
.text-blue {
  color: #00C8D5;
}
.bg-blue {
  background-color: #00C8D5;
}
.bg-blue-light {
  background-color:#00c7d548
}
.border-blue {
  border: 2px solid #00C8D5;
}
.btn-reduis {
  border-radius: 50px;
}
.text-gray {
  color: #494949;
}
.text-gray2 {
  color: #969696;
}
