.terms {
  font-size: 0.65rem;
  padding: 0.6rem;
  h1 {
    font-size: 3.2em;
    margin: 2rem 0px;
    text-transform: capitalize;
  }
  p {
    font-size: 1.8em;
    margin-bottom: 1rem;
  }
  p:last-of-type {
    font-size: 1.6em;
  }
  ul {
    padding: 1rem;
    font-size: 1.6em;
    li {
      list-style: decimal;
      margin-bottom: 1rem;
    }
  }
  @media screen and (max-width: 768px) {
    font-size: 0.5rem;
  }
}
