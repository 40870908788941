.normalTab {
  background-color: #ecebe9;
  color: black;
  padding: 10px 24px;
  border-radius: 50px;
  margin: 0px 10px;
}
.activeTab {
  border-radius: 50px;
  background-color: #ffb951;
  color: white;
  padding: 10px 24px;
  margin: 0px 10px;
  max-width: max-content;
}
