.signupForm {
  display: flex;
  flex-direction: column;
  gap: 1.6em;
  width: 80%;
  @media screen and (max-width: 768px) {
    font-size: 0.5rem;
  }
  .selectBox {
    justify-content: center;
    justify-items: center;
    height: 40px;
    border: 0px solid #999;
    font-size: 18px;
    color: black;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 2px #ccc;
    padding-left: 5px;
    width: 90%;
  }
  .inputBtn {
    outline: 0;
    border-width: 0 0 1px;
    border-color: #c1c1c1;
    padding-bottom: 12px;
    width: 90%;
    font-size: 2em;

    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    cursor: text;
    &:focus {
      outline: none;
      box-shadow: none;
      border-color: #a3aaa3;
    }
    &::placeholder {
      color: #5e6d55;
    }
  }

  select.inputBtn {
    cursor: pointer;
    &:not(:valid) {
      color: #5e6d55;
    }
  }
  .name {
    display: flex;
    gap: 1.6em;
  }
  .formBtn {
    background: #00c8d5;
    height: 45px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 2em;
    color: #fff;
    padding: 12px;
    border: none;
    outline: none;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.06))
      drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1));
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: darken(#00c8d5, 2%);
    }
  }
}
